<script setup>
import { useProgressStorage } from '~/composables/useProgressStorage';

const { progress, currentChapter, currentChapterItem, initModule, clearProgress, completeChapterIds, completeChapterItem, moduleProgress } = useProgressStorage();

const progressPercentage = computed(() => {
    return moduleProgress(2);
});
</script>

<template>
    <slot
        name="default"
        :module-progress="moduleProgress"
        :complete-chapter-ids="completeChapterIds"
        :init-module="initModule"
        :complete-chapter-item="completeChapterItem"
        :clear-progress="clearProgress"
        :progress="progress"
        :progress-percentage="progressPercentage"
        :current-chapter="currentChapter"
        :current-chapter-item="currentChapterItem"
    />
</template>
