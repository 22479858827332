<template>
    <transition
        v-if="parentId || members"
        name="fade"
    >
        <div
            v-if="fetchPending || pending"
            class="members"
        >
            <section class="members__section">
                <BaseMember>
                    <template #picture>
                        <content-placeholders-img style="width: 80px; height: 80px;" />
                    </template>

                    <template #default>
                        <header>
                            <h5><content-placeholders-heading /></h5>
                        </header>
                        <content-placeholders-text :lines="1" />
                    </template>
                </BaseMember>
            </section>

            <section class="members__section">
                <BaseMember>
                    <template #default>
                        <header>
                            <h5><content-placeholders-heading /></h5>
                        </header>

                        <content-placeholders-text :lines="1" />
                    </template>
                </BaseMember>
            </section>

            <section class="members__section">
                <BaseMember
                    v-for="i in [1,2,3,4,5,6,7,8,9,10,11]"
                    :key="`member${i}`"
                >
                    <template #default>
                        <header>
                            <h5><content-placeholders-heading /></h5>
                        </header>

                        <content-placeholders-text :lines="1" />
                    </template>
                </BaseMember>
            </section>
        </div>

        <div
            v-else
            class="members"
            :data-width="isFaculty ? 2 : 3"
        >
            <section
                v-if="isFaculty && members && members.length"
                class="members__section"
            >
                <BaseMember
                    v-for="(member, index) in members"
                    :key="`member${index}`"
                >
                    <template
                        v-if="member.memberPicture && member.memberPicture.length"
                        #picture
                    >
                        <img
                            :src="member.memberPicture[0].url"
                            alt=""
                            loading="lazy"
                            width="80"
                        >
                    </template>

                    <template #default>
                        <header>
                            <h5>{{ member.memberTitle }} {{ member.memberInitials }} {{ member.memberLastName }} <template v-if="member.memberCountryCode">({{ member.memberCountryCode }})</template></h5>
                            <div>{{ member.memberType }}</div>
                        </header>
                    </template>
                </BaseMember>
            </section>

            <section
                v-if="!isFaculty && chairs && chairs.length"
                class="members__section"
            >
                <BaseMember
                    v-for="(chair, index) in chairs"
                    :key="`chair${index}`"
                >
                    <template
                        v-if="chair.memberPicture && chair.memberPicture.length"
                        #picture
                    >
                        <img
                            :src="chair.memberPicture[0].url"
                            alt=""
                            loading="lazy"
                            width="80"
                        >
                    </template>

                    <template #default>
                        <header>
                            <h5>{{ chair.memberTitle }} {{ chair.memberInitials }} {{ chair.memberLastName }} <template v-if="chair.memberCountryCode">({{ chair.memberCountryCode }})</template></h5>
                            <div
                                v-for="(type, typeIndex) in getCleanMemberTypes(chair)"
                                :key="`chair-type${typeIndex}`"
                            >
                                {{ type }}
                            </div>
                        </header>
                        <a
                            v-if="chair.conflictOfInterests && chair.conflictOfInterests.length"
                            href="#"
                            @click.prevent="openModal = chair"
                        >
                            Conflict of interest
                        </a>
                    </template>
                </BaseMember>
            </section>

            <section
                v-if="!isFaculty && coChairs && coChairs.length"
                class="members__section"
            >
                <BaseMember
                    v-for="(coChair, index) in coChairs"
                    :key="`co${index}`"
                >
                    <template
                        v-if="coChair.memberPicture && coChair.memberPicture.length"
                        #picture
                    >
                        <img
                            :src="coChair.memberPicture[0].url"
                            alt=""
                            loading="lazy"
                            width="80"
                        >
                    </template>

                    <template #default>
                        <header>
                            <h5>{{ coChair.memberTitle }} {{ coChair.memberInitials }} {{ coChair.memberLastName }} <template v-if="coChair.memberCountryCode">({{ coChair.memberCountryCode }})</template></h5>
                            <div
                                v-for="(type, typeIndex) in getCleanMemberTypes(coChair)"
                                :key="`co-type${typeIndex}`"
                            >
                                {{ type }}
                            </div>
                        </header>
                        <a
                            v-if="coChair.conflictOfInterests && coChair.conflictOfInterests.length"
                            href="#"
                            @click.prevent="openModal = coChair"
                        >
                            Conflict of interest
                        </a>
                    </template>
                </BaseMember>
            </section>

            <section
                v-if="!isFaculty && viceChairs && viceChairs.length"
                class="members__section"
            >
                <BaseMember
                    v-for="(viceChair, index) in viceChairs"
                    :key="`vice${index}`"
                >
                    <template #default>
                        <header>
                            <h5>{{ viceChair.memberTitle }} {{ viceChair.memberInitials }} {{ viceChair.memberLastName }} <template v-if="viceChair.memberCountryCode">({{ viceChair.memberCountryCode }})</template></h5>
                            <div
                                v-for="(type, typeIndex) in getCleanMemberTypes(viceChair)"
                                :key="`vice-type${typeIndex}`"
                            >
                                {{ type }}
                            </div>
                        </header>
                        <a
                            v-if="viceChair.conflictOfInterests && viceChair.conflictOfInterests.length"
                            href="#"
                            @click.prevent="openModal = viceChair"
                        >
                            Conflict of interest
                        </a>
                    </template>
                </BaseMember>
            </section>

            <section
                v-if="!isFaculty && editorsInChief && editorsInChief.length"
                class="members__section"
            >
                <BaseMember
                    v-for="(editorInChief, index) in editorsInChief"
                    :key="`editor-in-chief${index}`"
                >
                    <template #default>
                        <header>
                            <h5>{{ editorInChief.memberTitle }} {{ editorInChief.memberInitials }} {{ editorInChief.memberLastName }} <template v-if="editorInChief.memberCountryCode">({{ editorInChief.memberCountryCode }})</template></h5>
                            <div
                                v-for="(type, typeIndex) in getCleanMemberTypes(editorInChief)"
                                :key="`editor-in-chief-type${typeIndex}`"
                            >
                                {{ type }}
                            </div>
                        </header>
                        <a
                            v-if="editorInChief.conflictOfInterests && editorInChief.conflictOfInterests.length"
                            href="#"
                            @click.prevent="openModal = editorInChief"
                        >
                            Conflict of interest
                        </a>
                    </template>
                </BaseMember>
            </section>

            <section
                v-if="!isFaculty && allNonChairMembers && allNonChairMembers.length"
                class="members__section"
            >
                <BaseMember
                    v-for="(member, index) in allNonChairMembers"
                    :key="`member-${index}`"
                >
                    <template #default>
                        <header>
                            <h5>{{ member.memberTitle }} {{ member.memberInitials }} {{ member.memberLastName }} <template v-if="member.memberCountryCode">({{ member.memberCountryCode }})</template></h5>
                            <div
                                v-for="(type, typeIndex) in getCleanMemberTypes(member)"
                                :key="`member-type${typeIndex}`"
                            >
                                {{ type }}
                            </div>
                        </header>
                        <a
                            v-if="member.conflictOfInterests && member.conflictOfInterests.length"
                            href="#"
                            @click.prevent="openModal = member"
                        >
                            Conflict of interest
                        </a>
                    </template>
                </BaseMember>
            </section>

            <BaseModal
                :is-visible="!!openedConflict"
                @close="openModal = null"
            >
                <template v-if="!!openedConflict">
                    <h4>{{ openedConflict.title }} - conflict of interest</h4>
                    Updated: {{ openedConflict.date }}
                    <p
                        v-for="(conflict, type) in openedConflict.conflictsByType"
                        :key="`conflict${type}`"
                    >
                        <strong>{{ type }}</strong><br>
                        <span>{{ getCompanies(conflict.companies) }}</span>
                    </p>
                </template>
            </BaseModal>
        </div>
    </transition>
</template>

<script setup>
import {groupBy, orderBy} from 'lodash-es';
import {compareDesc, parseISO} from 'date-fns';
import MembersQuery from '~/graphql/queries/members/members.graphql';

const { formats, formatSingleDate } = useDate();

const props = defineProps({
    pending: {
        type: Boolean,
        required: false,
        default: false
    },
    parentId: {
        type: Number,
        required: false,
        default: null
    },
    members: {
        type: Array,
        required: false,
        default: () => []
    },
    showAffiliatesAssociates: {
        type: Boolean,
        required: false,
        default: true
    },
    isFaculty: {
        type: Boolean,
        required: false,
        default: false
    }
});

let data, fetchPending;

if (props.parentId) {
    const response = await useLazyAsyncQuery({
        query: MembersQuery,
        variables: {
            parent: props.parentId
        }
    });

    ({ data, pending: fetchPending } = response);
}

const allMembers = computed(() => {
    if (props.parentId && data && data?.value) {
        return data.value?.entries;
    } else {
        return props.members;
    }
});

const openModal = ref(null);

const chairs = computed(() => {
    const memberArray = allMembers?.value?.filter(member => member.memberType?.match(/^(secretary[- ]general(?![- ]?elect)|chair(man)?(?![- ]?elect))/i));
    return orderBy(memberArray, ['memberLastName'], ['asc']);
});
const coChairs = computed(() => {
    const memberArray = allMembers?.value?.filter(member => member.memberType?.match(/^(secretary[- ]general(?![- ]?elect)|co[- ]chair(man)?(?![- ]?elect))/i));
    return orderBy(memberArray, ['memberLastName'], ['asc']);
});
const viceChairs = computed(() => {
    const memberArray = allMembers?.value?.filter(member => member.memberType?.match(/^(adjunct[- ]secretary[- ]general(?![- ]?elect)|vice[- ]chair(man)?(?![- ]?elect))/i));
    return orderBy(memberArray, ['memberLastName'], ['asc']);
});
const editorsInChief = computed(() => {
    const memberArray = allMembers?.value?.filter(member => member.memberType?.match(/^(editor[- ]in[- ]chief)/i));
    return orderBy(memberArray, ['memberLastName'], ['asc']);
});
const consultants = computed(() => {
    const memberArray = allMembers?.value?.filter(member => member.memberType?.match(/^(consultant)/i));
    return orderBy(memberArray, ['memberLastName'], ['asc']);
});
const exOfficios = computed(() => {
    const memberArray = allMembers?.value?.filter(member => member.memberType?.match(/^(ex-officio)/i));
    return orderBy(memberArray, ['memberLastName'], ['asc']);
});
const honoraryMembers = computed(() => {
    const memberArray = allMembers?.value?.filter(member => member.memberType?.match(/^(honorary[- ]member)/i));
    return orderBy(memberArray, ['memberLastName'], ['asc']);
});
const affiliates = computed(() => {
    const memberArray = allMembers.value?.filter(member => member.memberType?.match(/^(affiliate)/i));
    return orderBy(memberArray, ['memberLastName'], ['asc']);
});
const associates = computed(() => {
    const memberArray = allMembers?.value?.filter(member => member.memberType?.match(/^(associate)/i));
    return orderBy(memberArray, ['memberLastName'], ['asc']);
});
const regularMembers = computed(() => {
    // Filter regular members
    let memberArray = allMembers?.value?.filter(member =>
        !chairs.value.includes(member) &&
        !coChairs.value.includes(member) &&
        !viceChairs.value.includes(member) &&
        !editorsInChief.value.includes(member) &&
        !consultants.value.includes(member) &&
        !exOfficios.value.includes(member) &&
        !honoraryMembers.value.includes(member) &&
        !affiliates.value.includes(member) &&
        !associates.value.includes(member)
    );
    // Change member-type for regular members to "member"
    memberArray = memberArray.map((member) => {
        return { ...member, cleanType: 'Member' };
    });
    return orderBy(memberArray, ['memberLastName'], ['asc']);
});
const allNonChairMembers = computed(() => {
    if (props.showAffiliatesAssociates) {
        return [].concat(regularMembers.value, affiliates.value, associates.value, consultants.value, exOfficios.value, honoraryMembers.value);
    }
    return [].concat(regularMembers.value, associates.value, consultants.value, exOfficios.value, honoraryMembers.value);
});
const openedConflict = computed(() => {
    if (openModal.value) {
        const groupedConflicts = groupBy(openModal.value.conflictOfInterests, item => item.type);
        const conflictsByType = {};
        let date = null;

        Object.keys(groupedConflicts).forEach((type) => {
            groupedConflicts[type].forEach((conflict) => {
                // Group by type and merge companies
                if (!conflictsByType[type]) {
                    conflictsByType[type] = {
                        type: conflict.type,
                        companies: [conflict.company]
                    };
                } else {
                    conflictsByType[type].companies.push(conflict.company);
                }
                // Set the date to the highest value
                if (conflict.submittedOn) {
                    // Example input is "2019-05-24"
                    const parsedDate = parseISO(conflict.submittedOn);
                    if (!date || compareDesc(date, parsedDate)) {
                        date = parsedDate;
                    }
                }
            });
        });

        return {
            title: openModal.value.title,
            date: formatSingleDate(date, formats.numericDate),
            conflictsByType
        };
    }
    return null;
});

function getCompanies(companies) {
    // Deduplicate and join
    return companies.filter((name, index) => {
        return companies.indexOf(name) === index;
    }).join(', ');
}

function getCleanMemberTypes(member) {
    return member.cleanType ? [member.cleanType] : member.memberType ? member.memberType.split(' & ') : [];
}
</script>

<style lang="less">
.members__section {
    display: grid;
    gap: var(--spacing-lg);
    margin-top: var(--spacing-lg);

    @media @q-md-min {
        grid-template-columns: repeat(2, 1fr);
    }

    @media @q-lg-min {
        grid-template-columns: repeat(3, 1fr);
        margin-top: var(--spacing-xl);
        gap: var(--spacing-xl);
    }
}

.members[data-width='2']  .members__section {
    @media @q-lg-min {
        grid-template-columns: repeat(2, 1fr);
    }
}
</style>
