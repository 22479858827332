<template>
    <BaseSection>
        <template #title>
            <h2 v-show="title && hasMainTitle">{{ title }}</h2>
        </template>
        <template #description>
            <p v-show="description">{{ description }}</p>
        </template>
        <template #default>
            <BaseAccordion :items="accordionItems">
                <template #title="{ item }">
                    {{ item.title }}
                </template>
                <template #default="{ item }">
                    <!-- eslint-disable vue/no-v-html -->
                    <div
                        data-allow-anchor
                        v-html="item.content"
                    />
                    <!-- eslint-enable vue/no-v-html -->
                </template>
            </BaseAccordion>
        </template>
    </BaseSection>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            required: false,
            default: null,
        },
        description:{
            type: String,
            required: false,
            default: null,
        },
        items: {
            type: Array,
            required: false,
            default: null,
        },
    },

    computed: {
        accordionItems() {
            const fallbackTitle = this.items.length === 1 ? this.title : null;

            return this.items.map((item) => {
                return {
                    title: item?.blockTitle ?? fallbackTitle,
                    content: item?.richText ?? item?.transcript,
                };
            });
        },
        hasMainTitle() {
            return this.items.length > 1;
        },
    },
};
</script>
