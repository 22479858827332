<script setup>
import useSrcSets from '~/utils/srcset';

defineProps({
    allowImageEnlargement: {
        type: Boolean,
        default: true
    },
    caption: {
        type: String,
        default: null,
        required: false
    },
    image: {
        type: Array,
        default: null,
        required: true
    }
});

const openModal = ref(false);

const { getSrcSet } = useSrcSets();
</script>

<template>
    <div class="base-light-box">
        <slot name="image" />

        <a
            v-if="allowImageEnlargement"
            class="base-light-box__button"
            target="_blank"
            rel="noopener"
            aria-label="Click to enlarge"
            title="Click to enlarge"
            @click="openModal = true"
        >
            <BaseIcon icon="fullscreen" />
            Enlarge image
        </a>

        <BaseModal
            v-if="allowImageEnlargement"
            :is-visible="openModal"
            :data-large="true"
            @close="openModal = false"
        >
            <BaseFigure
                :src="image[0].intermediateUrl"
                responsive
                :srcset="getSrcSet(image[0], 'responsive')"
                width="844"
                sizes="100vw"
                alt=""
            >
                <template #caption>{{ caption }}</template>
            </BaseFigure>
        </BaseModal>
    </div>
</template>

<style lang="less">
.base-light-box__button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: .5rem;
    cursor: pointer;
    color: inherit;


    .dn-icon svg  {
        font-size: 1.25rem;

        path {
            stroke: none;
            fill: currentColor;
        }
    }
}
</style>