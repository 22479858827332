import theme from '../default/index.js';

/**
 * This is an override theme file for the EAU Nurses site
 */

import Color from 'color';

const primaryColor = Color('#DFAC38'),
    secondaryColor = Color('#FFE4B1');

const color = {

    // TODO: sync with the design system - 
    // https://www.figma.com/design/Cq3mOr60RoID3oIRjK8B73/EAU-%E2%80%A2-Patient-Portal?node-id=9707-21963&t=KJqvfnvIdYbwVM0i-4
    primary: primaryColor.hex(),
    'primary-100': '#F3F7F6',
    'primary-200': '#E2F3FF',
    'primary-300': '#B2D5ED',
    'primary-500': '#2453AF',
    'primary-700': '#17346D',
    'primary-900': '#142443',

    'primary-light':  '#FFFAF0', //'primaryColor.lighten(0.15).hex(),
    'primary-dark': primaryColor.darken(0.25).hex(), // #B4861D

    darkest: 'var(--color-primary-700)',
    lightest: '#fdfdfe',

    // todo check why these are the base for our typography
    secondary: secondaryColor.hex(),
    'secondary-100': '#FFFAF0',
    'secondary-200': '#FFF5E1',
    'secondary-300': secondaryColor.hex(),
    'secondary-500': '#FED585',
    'secondary-700': '#E5A177',
    'secondary-900': '#DC814A',

    // TODO: where did these come from?
    'secondary-light': '#F4F5F7',
    'secondary-dark': '#152443',

    // todo rename to secondary colors
    'alt-light': 'var(--color-primary-200)',
    'alt-dark': 'var(--color-primary-500)',
    'alt-darker': '#var(--color-primary-700)',
    'alt-darkest': 'var(--color-secondary-dark)',

    'black-20': 'rgba(0, 0, 0, 0.16)',

    'light-gray': '#D6D6D6',
    'gray-100': '#F4F5F9',
    'gray-300': '#E3E3E3',
    'gray-500': '#BBBBBB',
    'gray-700': '#727272',
    'gray-900': '#4A4A49',

};

// Primary scale
color['primary-100'] = '#F3F7F6';
color['primary-200'] = '#E2F3FF';
color['primary-300'] = '#B5E1FF';
color['primary-500'] = '#2453AF';
color['primary-700'] = '#17346D';
color['primary-900'] = '#142443';

const flags = {
    // Quote
    '--quote-align': 'center',
    '--quote-font-weight': 'normal',
    '--quote-font-family': 'var(--font-family-primary)',
    '--quote-font-size-lg': '2.3125rem',
    '--quote-font-size-sm': '1.5rem',

    // Asset sizes
    '--desktop-quote-width': '25rem',
    '--desktop-video-width': '100%',

    // Colors
    '--home-bg-color': 'var(--color-primary-light)',
    '--nav-bg-color': 'var(--color-primary-light)',
    '--hero-bg-color': 'var(--color-primary-light)',
    '--footer-bg-color': 'var(--color-secondary)',

    '--button-primary-bg-color': 'var(--color-alt-dark)',
    '--button-primary-bg-color-hover': 'var(--color-alt-darker)',

    '--button-secondary-bg-color': 'var(--color-secondary-500)',
    '--button-secondary-bg-color-hover': 'var(--color-secondary-700)',
    '--button-secondary-text-color': 'var(--color-darkest)',

    '--button-bg-color-disabled': 'var(--color-black-20)',

    '--quicklink-hover-color': 'var(--color-alt-dark)',
    '--quicklink-visited-color': 'var(--color-primary)'

};

export default {
    ...theme,
    ...flags,
    color
};
