<script setup>
import { useProgressStorage } from '~/composables/useProgressStorage';

const { progress, completeChapterSlugs } = useProgressStorage();

const emit = defineEmits(['close']);

const route = useRoute();
const module = computed(() => {
    return progress.value?.modules.find(entry => entry.slug === route.params.module);
});

const completed = computed(() => {
    return completeChapterSlugs(module.value?.slug);
});

const target = ref(null);
</script>

<template>
    <div
        ref="target"
        class="base-module-progress-menu"
    >
        <hr>
        <ClientOnly>
            <ol v-if="module">
                <li
                    v-for="chapter in module.chapters"
                    :key="chapter.slug"
                    class="base-module-progress-menu__chapter"
                    :data-completed="completed?.includes(chapter.slug)"
                    :data-active="chapter?.slug?.toString() === route?.params?.chapter"
                >
                    <NuxtLink
                        :to="`/module/${module.slug}/${chapter.slug}`" 
                        class="base-module-progress-menu__chapter-link"
                        @click="emit('close')"
                    >
                        {{ chapter.title }}

                        <BaseIcon
                            v-if="completed?.includes(chapter.slug)"
                            icon="check-circle"
                            class="base-module-progress-menu__icon"
                        />
                        <BaseIcon
                            v-else-if="route.params.chapter && chapter.slug.toString() === route.params.chapter"
                            class="base-module-progress-menu__icon"
                            icon="pending"
                        />
                    </NuxtLink>

                    <menu>
                        <li
                            v-for="item in chapter.sections"
                            :key="item.slug"
                            class="base-module-progress-menu__item"
                            :data-active="item.slug.toString() === route.params.item"
                        >
                            <NuxtLink
                                :to="`/module/${module.slug}/${chapter.slug}/${item.slug}`"
                                @click="emit('close')"
                            >
                                {{ item.title }}
                            </NuxtLink>
                        </li>
                    </menu>
                </li>
            </ol>
        </ClientOnly>
    </div>
</template>

<style lang="less">
.base-module-progress-menu {
    ol {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 0;

            &:before {
                transform: none;
                left: 0;
            }
        }
    }

    hr {
        border: none;
        border-top: 2px solid rgba(0, 0, 0, .1);
        margin: 0;
        margin-bottom: 1.5rem;
    }
}

.base-module-progress-menu__chapter {
    .typography-h4();
    padding-left: 1.5rem;

    display: flex;
    flex-direction: column;
    gap: 1rem;

    menu {
        .typography-primary();
        list-style: none;
        margin: 0;
        padding: 0;

        display: flex;
        flex-direction: column;
        gap: .75rem;
    }

    &[data-active=true] {
        color: var(--color-primary-500);
    }
}

.base-module-progress-menu__item {
    font-size: var(--font-size-md);

    a {
        text-decoration: none;
        color: var(--color-primary-900);
    }

    &[data-active=true] a {
        color: var(--color-primary-500);
        font-weight: var(--font-weight-primary-bold);
    }
}

.base-module-progress-menu__close {
    font-size: 1.75rem;
    margin-left: -.2rem;
    margin-bottom: 2rem;
}

.base-module-progress-menu__icon {
    color: currentColor;
    font-size: 1.75rem;

    svg path {
        fill: currentColor;
        stroke: none;
    }
}

.base-module-progress-menu__chapter-link {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5rem;
    color: currentColor;
    text-decoration: none;
}
</style>