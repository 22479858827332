<script setup>
import {setVariablesFromConfigObject} from '@digitalnatives/css-variables';

const {activeSite} = useMultisite();
const loadingColor = ref('#CCC');
const isLoading = ref(true);

onBeforeMount(() => {
    isLoading.value = true;

    const group = activeSite.value?.group ?? activeSite.value?.handle;
    const rtl = activeSite.value?.rtl ?? false;

    import(`@/assets/theme/${group}/index.js`)
        .then(({default: theme}) => {
            loadingColor.value = theme.color.primary;
            if (rtl) {
                theme.direction = 'rtl';
            }
            setVariablesFromConfigObject(theme);
        })
        .catch(() => {
            loadingColor.value = '#FE6B20';
            console.warn(`Theme '${group}' not found, using default theme.`);
        });

    if (rtl) {
        document.documentElement.setAttribute('dir', 'rtl');
    }


    isLoading.value = false;
});
</script>

<template>
    <div
        class="layout"
    >
        <ClientOnly>
            <NuxtLoadingIndicator :loading-color="loadingColor" />
        </ClientOnly>

        <section class="layout__header">
            <TheModuleNavigation />
        </section>

        <slot />
    </div>
</template>

<style lang="less">
.layout {
    display: grid;
    grid-template-areas: 'header' 'notice' 'main' 'footer';
    grid-template-rows: auto auto 1fr auto;
    min-height: 100vh;
    gap: 0;
    margin: 0 auto;
    background: var(--color-lightest);

    h1 {
        font-size: var(--font-size-6xl);
    }

    ul > li {
        &:before {
            width: .25rem;
            height: .25rem;
        }
    }
}

.layout[data-menu-open=true] {
    &:before {
        content: '';
        display: block;
        position: fixed;
        z-index: 10;
        background: var(--color-black-30);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    @media @q-xl-min {
        overflow: auto;

        &:before {
            content: none;
            display: none;
        }
    }
}

.layout__header {
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: 100;
}

.layout__hero {
    .section__header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
    }

    @media @q-md-min {
        padding-inline: var(--grid-gap-sm);
    }
}

.layout__main {
    .grid-container();
    .vertical-padding();

    grid-area: main;
    justify-self: center;
}

.layout__main--narrow {
    .grid-container(true, var(--grid-maxWidth-content));
}

.layout__main--centered {
    align-content: center;
}

.layout__main--no-top-padding {
    padding-top: 0;

    .error:first-child {
        .vertical-padding();
    }
}

.layout__footer {
    grid-area: footer;
}
</style>