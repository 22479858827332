<template>
    <div
        v-if="embedUrl"
        class="embed embed--video"
    >
        <iframe
            :src="embedUrl"
            loading="lazy"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
        />
    </div>
</template>

<script setup lang="ts">
import type {BaseEmbedProps} from '~/components/BaseEmbed/BaseEmbed.types.js';

const props = defineProps<BaseEmbedProps>();

const embedUrl = computed(() => {
    if(!props.code.includes('youtube.com')) {
        return null;
    }

    // Direct video url
    if(!props.code.includes('embed')) {
        const url = new URL(props.code);
        const videoId = url.searchParams.get('v');
        if(!videoId) {
            return null;
        }
        return `https://www.youtube.com/embed/${videoId}`;
    }

    try {
        // Full embed code
        if(props.code.includes('<iframe')) {
            const src = props.code.match(/src="([^"]*)"/);
            return src?.[1];
        }

        // Direct embed url
        const url = new URL(props.code);
        return url.toString();

    } catch(e){
        // silence
        return null;
    }
});

</script>
