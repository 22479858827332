
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    if (!router) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    for (const route of mod.default || mod) {
      router.addRoute(route)
    }
    router.replace('')
  })
}

export function handleHotUpdate(_router) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
  }
}
import { default as _91_46_46_46slug_939fT49VtevFMeta } from "/builds/uroweb/uroweb-frontend/pages/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93cKSs5L7oBCMeta } from "/builds/uroweb/uroweb-frontend/pages/all-conditions/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93iXFYDE5AC6Meta } from "/builds/uroweb/uroweb-frontend/pages/all-situations/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93O89d3WG7CSMeta } from "/builds/uroweb/uroweb-frontend/pages/all-treatments/[...slug].vue?macro=true";
import { default as indexTe1oWMkC5lMeta } from "/builds/uroweb/uroweb-frontend/pages/chat/[botId]/index.vue?macro=true";
import { default as _91_46_46_46slug_93O564frYaQSMeta } from "/builds/uroweb/uroweb-frontend/pages/condition/[...slug].vue?macro=true";
import { default as contactZpI3H9BzRKMeta } from "/builds/uroweb/uroweb-frontend/pages/contact.vue?macro=true";
import { default as _91_46_46_46slug_933yIeREmzfSMeta } from "/builds/uroweb/uroweb-frontend/pages/education-events/[...slug].vue?macro=true";
import { default as education6wrIAZxcT2Meta } from "/builds/uroweb/uroweb-frontend/pages/education-events/education.vue?macro=true";
import { default as eventsgmNg6ChIIWMeta } from "/builds/uroweb/uroweb-frontend/pages/education-events/events.vue?macro=true";
import { default as _91_46_46_46slug_93Z3nO12Ju2MMeta } from "/builds/uroweb/uroweb-frontend/pages/guidelines/archive/[...slug].vue?macro=true";
import { default as indexg8SZHXax7tMeta } from "/builds/uroweb/uroweb-frontend/pages/guidelines/archive/index.vue?macro=true";
import { default as indexhOvbkcw5jrMeta } from "/builds/uroweb/uroweb-frontend/pages/guidelines/index.vue?macro=true";
import { default as index6G8qD3fdZ3Meta } from "/builds/uroweb/uroweb-frontend/pages/index.vue?macro=true";
import { default as indexaW2AxcjkehMeta } from "/builds/uroweb/uroweb-frontend/pages/module/[module]/[chapter]/[item]/index.vue?macro=true";
import { default as indexAreFC6Y46AMeta } from "/builds/uroweb/uroweb-frontend/pages/module/[module]/[chapter]/index.vue?macro=true";
import { default as endrDjePaKNI9Meta } from "/builds/uroweb/uroweb-frontend/pages/module/[module]/end.vue?macro=true";
import { default as indexVh9EI1lXbaMeta } from "/builds/uroweb/uroweb-frontend/pages/module/[module]/index.vue?macro=true";
import { default as _91_46_46_46slug_93FEJYJLbWBiMeta } from "/builds/uroweb/uroweb-frontend/pages/news/[...slug].vue?macro=true";
import { default as indexv89PugolXSMeta } from "/builds/uroweb/uroweb-frontend/pages/news/index.vue?macro=true";
import { default as _91slug_93zwrHcai4kRMeta } from "/builds/uroweb/uroweb-frontend/pages/offices/[slug].vue?macro=true";
import { default as _91_46_46_46slug_93HoCJtTMCclMeta } from "/builds/uroweb/uroweb-frontend/pages/press-releases/[...slug].vue?macro=true";
import { default as indexXrPNLuGfOdMeta } from "/builds/uroweb/uroweb-frontend/pages/press-releases/index.vue?macro=true";
import { default as profilecWmz9mM3qSMeta } from "/builds/uroweb/uroweb-frontend/pages/profile.vue?macro=true";
import { default as searchIdMGRtSCVVMeta } from "/builds/uroweb/uroweb-frontend/pages/search.vue?macro=true";
import { default as _91_46_46_46slug_93gdF8oHLWoJMeta } from "/builds/uroweb/uroweb-frontend/pages/sections/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93cakSnEG6nbMeta } from "/builds/uroweb/uroweb-frontend/pages/tags/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93ALOVQyi1hRMeta } from "/builds/uroweb/uroweb-frontend/pages/topics/[...slug].vue?macro=true";
import { default as _91_46_46_46slug_93Cs1bwXXr8IMeta } from "/builds/uroweb/uroweb-frontend/pages/undergraduates/[...slug].vue?macro=true";
import { default as indexrHKv49fLjXMeta } from "/builds/uroweb/uroweb-frontend/pages/undergraduates/index.vue?macro=true";
import { default as chapterfF3FsqWGheMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/chapter.vue?macro=true";
import { default as summary_45by_45yearkDqxdBOA1AMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/summary-by-year.vue?macro=true";
import { default as summary_45of_45changesUCF4NYuveqMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/summary-of-changes.vue?macro=true";
import { default as publications_45appendiceskwsKmhf46XMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/publications-appendices.vue?macro=true";
import { default as panelqFUXgjTUtFMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/panel.vue?macro=true";
import { default as related_45content9QTwVur7MuMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/related-content.vue?macro=true";
import { default as detailhzJsLnfOrYMeta } from "/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/detail.vue?macro=true";
export default [
  {
    name: "slug",
    path: "/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/[...slug].vue")
  },
  {
    name: "all-conditions-slug",
    path: "/all-conditions/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/all-conditions/[...slug].vue")
  },
  {
    name: "all-situations-slug",
    path: "/all-situations/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/all-situations/[...slug].vue")
  },
  {
    name: "all-treatments-slug",
    path: "/all-treatments/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/all-treatments/[...slug].vue")
  },
  {
    name: "chat-botId",
    path: "/chat/:botId()",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/chat/[botId]/index.vue")
  },
  {
    name: "condition-slug",
    path: "/condition/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/condition/[...slug].vue")
  },
  {
    name: "contact",
    path: "/contact",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/contact.vue")
  },
  {
    name: "education-events-slug",
    path: "/education-events/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/education-events/[...slug].vue")
  },
  {
    name: "education-events-education",
    path: "/education-events/education",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/education-events/education.vue")
  },
  {
    name: "education-events-events",
    path: "/education-events/events",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/education-events/events.vue")
  },
  {
    name: "guidelines-archive-slug",
    path: "/guidelines/archive/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/guidelines/archive/[...slug].vue")
  },
  {
    name: "guidelines-archive",
    path: "/guidelines/archive",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/guidelines/archive/index.vue")
  },
  {
    name: "guidelines",
    path: "/guidelines",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/guidelines/index.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/index.vue")
  },
  {
    name: "module-module-chapter-item",
    path: "/module/:module()/:chapter()/:item()",
    meta: indexaW2AxcjkehMeta || {},
    component: () => import("/builds/uroweb/uroweb-frontend/pages/module/[module]/[chapter]/[item]/index.vue")
  },
  {
    name: "module-module-chapter",
    path: "/module/:module()/:chapter()",
    meta: indexAreFC6Y46AMeta || {},
    component: () => import("/builds/uroweb/uroweb-frontend/pages/module/[module]/[chapter]/index.vue")
  },
  {
    name: "module-module-end",
    path: "/module/:module()/end",
    meta: endrDjePaKNI9Meta || {},
    component: () => import("/builds/uroweb/uroweb-frontend/pages/module/[module]/end.vue")
  },
  {
    name: "module-module",
    path: "/module/:module()",
    meta: indexVh9EI1lXbaMeta || {},
    component: () => import("/builds/uroweb/uroweb-frontend/pages/module/[module]/index.vue")
  },
  {
    name: "news-slug",
    path: "/news/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/news/[...slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/news/index.vue")
  },
  {
    name: "offices-slug",
    path: "/offices/:slug()",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/offices/[slug].vue")
  },
  {
    name: "press-releases-slug",
    path: "/press-releases/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/press-releases/[...slug].vue")
  },
  {
    name: "press-releases",
    path: "/press-releases",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/press-releases/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: profilecWmz9mM3qSMeta || {},
    component: () => import("/builds/uroweb/uroweb-frontend/pages/profile.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/search.vue")
  },
  {
    name: "sections-slug",
    path: "/sections/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/sections/[...slug].vue")
  },
  {
    name: "tags-slug",
    path: "/tags/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/tags/[...slug].vue")
  },
  {
    name: "topics-slug",
    path: "/topics/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/topics/[...slug].vue")
  },
  {
    name: "undergraduates-slug",
    path: "/undergraduates/:slug(.*)*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/undergraduates/[...slug].vue")
  },
  {
    name: "undergraduates",
    path: "/undergraduates",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/undergraduates/index.vue")
  },
  {
    name: "guideline-slug",
    path: "/guidelines/:slug",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/detail.vue"),
    children: [
  {
    name: "guideline-chapterSlug",
    path: "chapter/:chapterSlug",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/chapter.vue")
  },
  {
    name: "guideline-summary",
    path: "summary-of-changes",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/summary-of-changes.vue"),
    children: [
  {
    name: "guideline-summaryByYear",
    path: ":year",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/summary-by-year.vue")
  }
]
  },
  {
    name: "guideline-publications-appendices",
    path: "publications-appendices",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/publications-appendices.vue")
  },
  {
    name: "guideline-panel",
    path: "panel",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/panel.vue")
  },
  {
    name: "related-content",
    path: "related-content",
    component: () => import("/builds/uroweb/uroweb-frontend/components/TheGuidelines/pages/related-content.vue")
  }
]
  },
  {
    name: "offices-child-slug",
    path: "/offices/:slug/*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/[...slug].vue")
  },
  {
    name: "sections-child-slug",
    path: "/sections/:slug/*",
    component: () => import("/builds/uroweb/uroweb-frontend/pages/[...slug].vue")
  }
]